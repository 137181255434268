import React from "react";
import clsx from "clsx";
import { Link as GatsbyLink } from "gatsby";

interface Props {
  text: string;
  to: string;
  bold?: boolean;
  additionalClasses?: string;
}

function Link(props: Props) {
  const { text, to, bold, additionalClasses } = props;

  const defaultClasses = "";

  const linkBold = bold ? "font-bold" : "";

  return (
    <GatsbyLink
      to={to}
      className={clsx(defaultClasses, linkBold, additionalClasses)}
    >
      {text}
    </GatsbyLink>
  );
}

export default Link;
