// eslint-disable-next-line import/prefer-default-export
export const renamedFacetsTitles = [
  {
    title: "s_materialType",
    newTitle: "Type of Material",
  },
  {
    title: "s_creators",
    newTitle: "Author of Publication",
  },
  {
    title: "s_researchArea",
    newTitle: "General Research Area",
  },
  {
    title: "s_linguisticFeature",
    newTitle: "Linguistic Feature",
  },
  {
    title: "s_languageBeingLearned",
    newTitle: "Language being used/learned",
  },
  {
    title: "s_firstLanguage",
    newTitle: "First language of learners",
  },
  {
    title: "s_proficiencyOfLearners",
    newTitle: "Proficiency of learners",
  },
  {
    title: "s_participantType",
    newTitle: "Participant Type",
  },
  {
    title: "s_publicationDate",
    newTitle: "Date of Publication/Award",
  },
  {
    title: "s_publicationType",
    newTitle: "Type Publication(s)",
  },
  {
    title: "s_journalName",
    newTitle: "Journal title(s)",
  },
  {
    title: "s_bookTitle",
    newTitle: "Book Title",
  },
  {
    title: "s_language",
    newTitle: "Language in which any instructions are written",
  },
  {
    title: "s_dataType",
    newTitle: "Data Type",
  },
  {
    title: "s_proficiencyStudentsTaught",
    newTitle: "Proficiency of Student Taught",
  },
  {
    title: "s_genderOfLearners",
    newTitle: "Gender of users/learners",
  },
  {
    title: "s_yearsOfExperience",
    newTitle: "Years of Teaching Experience",
  },
  {
    title: "s_requiredSoftware",
    newTitle: "Proprietary Software Name",
  },
  // {
  //   title: "s_typeOfFile",
  //   newTitle: "Type of File",
  // },
  {
    title: "s_domainOfUse",
    newTitle: "Domains of use of language use/learning",
  },
  {
    title: "s_sourceOfFunding",
    newTitle: "Funding",
  },
  {
    title: "s_publisher",
    newTitle: "Publisher",
  },
  {
    title: "s_placeOfPublication",
    newTitle: "Place of Publication",
  },
  {
    title: "s_publicationEditor",
    newTitle: "Editor",
  },
  {
    title: "s_conferenceName",
    newTitle: "Conference Name",
  },
  // {
  //   title: "s_licenceAgreement",
  //   newTitle: "Licence Selection",
  // },
  // {
  //   title: "s_oasisLink",
  //   newTitle: "OASIS Summary",
  // },
];
