/* eslint-disable no-nested-ternary */
import { isEmpty } from "lodash";
import React, { useContext, useEffect, useState } from "react";

import {
  SearchDispatchContext,
  SearchStateContext,
} from "../../context/SearchContextProvider";

function camelCaseToHumanReadable(camelCaseString: string): string {
  let string = camelCaseString;

  if (string === "publicationAPAInlineReference") {
    string = "Publication Reference";
  }

  const words = string.split(/(?=[A-Z])/);

  return words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

function RemoveIconSmall() {
  return (
    <div role="button" className="inline p-0" aria-label="Remove Other code">
      <svg
        height="14"
        width="14"
        viewBox="0 0 20 20"
        aria-hidden="true"
        focusable="false"
        className="css-tj5bde-Svg inline"
      >
        <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
      </svg>
    </div>
  );
}

export default function CurrentFilters() {
  const searchState = useContext(SearchStateContext) || {
    searchData: {
      filters: [],
    },
  };

  const searchDispatch = useContext(SearchDispatchContext) || "";

  const [currentFilters, setCurrentFilters] = useState("");

  /**
   * Remove a selected option from the filter list
   * @param key string like `s_materialType`
   * @param values object all current selected options
   * @param removeValue option to be removed
   */
  const removeClickHandler = (
    key: string,
    values: any,
    removeValue: string
  ) => {
    const remainingOptions = values.filter(
      (item: any) => item.label !== removeValue
    );

    searchDispatch({
      type: "facetChangeHandler",
      value: remainingOptions,
      fieldName: key,
    });
  };

  /**
   * Clear all selected filters
   */
  const removeAllClickHandler = () => {
    Object.entries(searchState?.searchData?.filters).map(([key]) => {
      searchDispatch({
        type: "facetChangeHandler",
        value: [],
        fieldName: key,
      });
    });
  };

  useEffect(() => {
    const filters = Object.entries(searchState?.searchData?.filters)
      .map(([key, values]) => {
        const readableKey = camelCaseToHumanReadable(key.replace("s_", ""));

        return values
          ? values.map((choosenOptionValue: any) => {
              return (
                <div
                  className="cursor-pointer rounded mr-2 mb-2 pl-2 pr-1 py-1 inline bg-gray-light-2 css-12a83d4-MultiValueRemove"
                  onClick={() =>
                    removeClickHandler(key, values, choosenOptionValue.label)
                  }
                  onKeyDown={() =>
                    removeClickHandler(key, values, choosenOptionValue.label)
                  }
                  role="button"
                  tabIndex={0}
                  key={choosenOptionValue.label}
                >
                  <span className="whitespace-nowrap pr-1">
                    {`${readableKey}: ${choosenOptionValue?.label
                      ?.replace(/\(\d+\)/, "")
                      .trim()}`}
                  </span>
                  <RemoveIconSmall />
                </div>
              );
            })
          : null;
      })
      .filter((item) => !isEmpty(item));

    setCurrentFilters(filters);
  }, [searchState]);

  const clearAllFilters = (
    <div
      className="cursor-pointer rounded mr-2 mb-2 pl-2 pr-1 py-1 inline bg-gray-light-2 css-12a83d4-MultiValueRemove"
      onClick={removeAllClickHandler}
      onKeyDown={removeAllClickHandler}
      role="button"
      tabIndex={0}
    >
      <span className="whitespace-nowrap">Clear all filters</span>
      <RemoveIconSmall />
    </div>
  );

  return !isEmpty(currentFilters) ? (
    <div className="inline-block w-full border-b border-b-gray-2 mb-5 pb-2 text-sm leading-8">
      Current filters: {currentFilters} <span className="mr-2">or</span>{" "}
      {clearAllFilters}
    </div>
  ) : (
    ""
  );
}
