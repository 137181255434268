import { isEmpty } from "lodash";

const getChildren = (filterOption: string, ddArray: []) => {
  const materialType = ddArray.filter((item) => item.label === filterOption);

  if (!isEmpty(materialType)) {
    const { subItems } = materialType[0];

    const children = subItems
      .map((id: string) => ddArray.filter((item) => item.id === id))
      .map((item: any) => item[0].label);

    return children.map((childLabel: string) => {
      return { value: childLabel, label: childLabel };
    });
  }
  return { value: filterOption, label: filterOption };
};

/**
 * Find the children of the parent
 * This only applies to `Type of Material`, `General Research Area`, `Proficiency of learners` and `Participant Type`
 */
const findChildren = (searchData: any, ddArrays: any) => {
  const filters = { ...searchData.filters };

  Object.keys(filters).map((filterkey: string) => {
    if (!isEmpty(ddArrays[filterkey])) {
      const children = filters[filterkey].map((filterValue: any) => {
        return getChildren(filterValue.value, ddArrays[filterkey]);
      });

      if (!isEmpty(children)) {
        children.map((items) => {
          filters[filterkey] = [...filters[filterkey], ...items];
        });
      } else {
        filters[filterkey] = searchData.filters[filterkey];
      }
    }

    return "";
  });

  return { ...searchData, filters };
  // return searchData;
};

export { getChildren, findChildren };
