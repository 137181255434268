import React from "react";
import clsx from "clsx";
import { v4 as uuidv4 } from "uuid";
import { range } from "lodash";

const showNumberOfPages = 2;

const stepClasses =
  "p-2 underline-offset-4 hover:underline transition-all duration-300";

const stepActiveClasses = "font-bold underline text-blue";

interface PaginationStepProps {
  page: number;
  text: string;
  currentPage: number;
  setCurrentPageHandler: any;
  query: string;
  canBeActive?: boolean;
  showLink?: boolean;
  currentParams: any;
}

const PaginationStep = (props: PaginationStepProps) => {
  let searchParams: any = [];

  Object.keys(props.currentParams).forEach((key) => {
    searchParams.push(`${key}=${props.currentParams[key]}`);
  });

  // const href = `/search/?q=${props.query}&page=${props.page}`;

  const href = `/search/?${searchParams.join("&")}&page=${props.page}`;

  const classes = clsx(
    stepClasses,
    props.canBeActive && props.page === props.currentPage
      ? stepActiveClasses
      : ""
  );

  return (
    <li className="page-item" key={uuidv4()}>
      {props.showLink ? (
        <>
          <a
            className={classes}
            href={href}
            onClick={() => props.setCurrentPageHandler(props.page)}
          >
            {props.text}
          </a>
        </>
      ) : (
        <span className={classes}>{props.text}</span>
      )}
    </li>
  );
};

interface PaginationProps {
  params: any;
  currentPage: number;
  setCurrentPageHandler: any;
  query: string;
  totalPages: number;
}

const Pagination = (props: PaginationProps) => {
  const _currentPage =
    typeof props.currentPage === "string"
      ? parseInt(props.currentPage)
      : props.currentPage;

  const _totalPages =
    typeof props.totalPages === "string"
      ? parseInt(props.totalPages)
      : props.totalPages;

  const previousStep =
    _currentPage > 1 ? (
      <PaginationStep
        key={uuidv4()}
        query={props.query}
        page={_currentPage - 1}
        text="<"
        currentPage={_currentPage}
        setCurrentPageHandler={props.setCurrentPageHandler}
        showLink={true}
        currentParams={props.params}
      />
    ) : (
      ""
    );

  const backStepsStart =
    _currentPage - 1 - showNumberOfPages <= 0
      ? 1
      : _currentPage - 1 - showNumberOfPages;

  const backStepsEnd = _currentPage;

  const backSteps = [...range(backStepsStart, backStepsEnd)].map((step) => {
    return (
      <PaginationStep
        key={uuidv4()}
        query={props.query}
        page={step}
        text={step.toString()}
        currentPage={_currentPage}
        setCurrentPageHandler={props.setCurrentPageHandler}
        canBeActive={true}
        showLink={true}
        currentParams={props.params}
      />
    );
  });

  const thisStep = (
    <PaginationStep
      key={uuidv4()}
      query={props.query}
      page={_currentPage}
      text={_currentPage.toString()}
      currentPage={_currentPage}
      setCurrentPageHandler={props.setCurrentPageHandler}
      canBeActive={true}
      currentParams={props.params}
    />
  );

  const nextStepsStart =
    _currentPage + 1 >= _totalPages ? _totalPages : _currentPage + 1;

  const nextStepsEnd =
    nextStepsStart + showNumberOfPages >= _totalPages
      ? _totalPages
      : nextStepsStart + showNumberOfPages;

  const nextStepsArray =
    _currentPage === _totalPages
      ? []
      : nextStepsStart === nextStepsEnd
      ? [_totalPages]
      : range(nextStepsStart, nextStepsEnd + 1);

  const nextSteps = nextStepsArray.length
    ? [...nextStepsArray].map((step) => {
        return (
          <PaginationStep
            key={uuidv4()}
            query={props.query}
            page={step}
            text={step.toString()}
            currentPage={_currentPage}
            setCurrentPageHandler={props.setCurrentPageHandler}
            canBeActive={true}
            showLink={true}
            currentParams={props.params}
          />
        );
      })
    : "";

  const nextStep =
    _currentPage < _totalPages ? ( // TO DO, what the total amount of pages??
      <PaginationStep
        key={uuidv4()}
        query={props.query}
        page={_currentPage + 1}
        text=">"
        currentPage={_currentPage}
        setCurrentPageHandler={props.setCurrentPageHandler}
        showLink={true}
        currentParams={props.params}
      />
    ) : (
      ""
    );

  return (
    <div className="flex justify-center">
      <nav aria-label="Page navigation example">
        <ul className="flex list-style-none">
          {previousStep}
          {backSteps}
          {thisStep}
          {nextSteps}
          {nextStep}
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
